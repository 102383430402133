import React, { useEffect, useState } from 'react'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/dayjs'
import dayjs from 'dayjs'
import { InputRightElement } from '../InputElement'
import InputGroup from '../InputGroup'
import Input from '../Input'
import { FiCalendar } from 'react-icons/fi'
import PropTypes from 'prop-types'

const DatePickerComponent = (props) => {
  const {
    onChange,
    value,
    disabled,
    disableFuture,
    disablePast,
    inputProps,
    minDate,
    maxDate,
    clearable,
    onBlur,
    variant,
    size,
    placeholder,
    ...rest
  } = props

  const [open, setOpen] = useState(false)
  const [selectedDate, handleDateChange] = useState(value ? value : null)

  const changeValue = (val) => {
    if (val) {
      handleDateChange(dayjs(val).format())
      onChange(dayjs(val).format())
    } else {
      handleDateChange(null)
      onChange(null)
    }
  }

  /* eslint-disable */
  useEffect(() => {
    if (selectedDate !== value) {
      handleDateChange(value)
    }
  }, [value])

  const Field = () => {
    return (
      <InputGroup variant={variant} size={size}>
        <InputRightElement cursor='pointer'>
          <FiCalendar onClick={() => setOpen(true)} />
        </InputRightElement>
        <Input
          placeholder={placeholder ? placeholder : 'Enter Date'}
          disabled={disabled}
          onFocus={() => setOpen(true)}
          onChange={null}
          readOnly
          variant={variant}
          size={size}
          value={selectedDate ? dayjs(selectedDate).format(props.format) : null}
          {...inputProps}
        />
      </InputGroup>
    )
  }

  return (
    <MuiPickersUtilsProvider libInstance={dayjs} utils={MomentUtils}>
      <DatePicker
        label='Date'
        value={selectedDate}
        minDate={minDate}
        maxDate={maxDate}
        onChange={(v) => changeValue(v)}
        animateYearScrolling={false}
        TextFieldComponent={Field}
        format={props.format}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        disabled={disabled}
        disableFuture={disableFuture}
        disablePast={disablePast}
        clearable={clearable}
        emptyLabel={placeholder ? placeholder : 'Select Date'}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  )
}

DatePickerComponent.defaultProps = {
  autoOk: true,
  format: 'DD/MM/YYYY',
  inputProps: {
    variant: 'outline',
    bg: 'global.elementBg'
  },
  clearable: true,
  onChange: (date) => console.log(date)
}

DatePickerComponent.displayName = 'DatePicker'

DatePickerComponent.propTypes = {
  /**
   * Whether the datepicker should close on selection of a date
   */
  autoOk: PropTypes.bool,
  /**
   * The date format
   */
  format: PropTypes.string,
  /**
   * Function to fire when a date is selected.  Passes a moment object
   */
  onChange: PropTypes.func,
  /**
   * The value
   */
  value: PropTypes.string,
  /**
   * Disable future dates
   */
  disableFuture: PropTypes.bool,
  /**
   * Disable past dates
   */
  disablePast: PropTypes.bool,
  /**
   * The props for the input element (eg variant, width)
   */
  inputProps: PropTypes.object,
  /**
   * Max selectable date (ParsableDate)
   */
  maxDate: PropTypes.string,
  /**
   * Min selectable date (ParsableDate)
   */
  minDate: PropTypes.string,
  /**
   * The variant of the input field
   */
  variant: PropTypes.oneOf(['outline', 'filled', 'unstyled', 'flushed']),
  /**
   * The size of the input field
   */
  size: PropTypes.oneOf(['sm', 'md', 'lg'])
}

export default DatePickerComponent
