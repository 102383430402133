import React, {
  useState,
  forwardRef,
  useRef,
  createContext,
  useContext
} from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { usePopper } from 'react-popper'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { useOutsideClick } from '../hooks'
import PseudoBox from '../PseudoBox'
import Box from '../Box'
import Button from '../Button'
import { useMenuItemStyle } from './styles'
import styled from '@emotion/styled'
import childrenWithProps from '../utils/childrenWithProps'

const PopperContext = createContext()

const MenuButton = forwardRef((props, ref) => {
  const {
    setReferenceElement,
    setIsOpen,
    isOpen,
    triggerElement,
    triggerProps
  } = useContext(PopperContext)
  const { children } = props
  return triggerElement ? (
    <Box
      display='inline-block'
      ref={setReferenceElement}
      onClick={() => setIsOpen(!isOpen)}
    >
      {triggerElement}
    </Box>
  ) : (
    <Button
      rightIcon={<MdKeyboardArrowDown />}
      ref={setReferenceElement}
      onClick={() => setIsOpen(!isOpen)}
      {...triggerProps}
    >
      {children}
    </Button>
  )
})

const Arrow = styled(PseudoBox)`
  width: 12px;
  height: 12px;
  z-index: -1;
`

export const DropdownWrapper = styled(PseudoBox)`
  &[data-popper-placement^='bottom'] {
    .arrow {
      top: -6px;
    }
  }
  &[data-popper-placement^='top'] {
    .arrow {
      bottom: -6px;
    }
  }
  &[data-popper-placement^='right'] {
    .arrow {
      left: -6px;
    }
  }
  &[data-popper-placement^='left'] {
    .arrow {
      left: -6px;
    }
  }
`

const Dropdown = (props) => {
  const {
    placement,
    overlay,
    arrow,
    children,
    size,
    closeOnSelect,
    triggerElement,
    triggerProps,
    visible,
    bg,
    ...rest
  } = props
  const [referenceElement, setReferenceElement] = useState(null)
  const container = useRef()
  const [isOpen, setIsOpen] = React.useState(!!visible)
  const dropdownContainer = useRef(null)
  const setPopperElement = useRef(null)
  useOutsideClick(setPopperElement, setIsOpen)
  const [arrowElement, setArrowElement] = useState(null)
  const { styles, attributes } = usePopper(
    referenceElement,
    setPopperElement.current,
    {
      placement: placement,
      modifiers: [
        {
          name: 'arrow',
          options: { element: arrowElement, padding: 18 }
        },
        {
          name: 'offset',
          options: {
            offset: [0, arrow ? 7 : 4]
          }
        }
      ]
    }
  )

  const baseStyle = {
    bg: bg || 'global.elementBg',
    position: 'relative',
    borderRadius: 'dropdown',
    h: 'auto',
    w: 'auto',
    boxShadow: 'dropdown',
    border: '1px',
    borderColor: 'borderColour',
    zIndex: 9000,
    ...rest
  }

  return (
    <PopperContext.Provider
      value={{
        setReferenceElement,
        setIsOpen,
        isOpen,
        triggerElement,
        triggerProps,
        closeOnSelect,
        size
      }}
    >
      <div ref={container}>
        <MenuButton ref={dropdownContainer}>{children}</MenuButton>
      </div>
      {isOpen &&
        ReactDOM.createPortal(
          <div ref={dropdownContainer}>
            <DropdownWrapper
              {...rest}
              {...baseStyle}
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              <PseudoBox
                zIndex='10'
                bg={bg || 'global.elementBg'}
                pos='relative'
                py='0px'
                borderRadius='dropdown'
                overflow='hidden'
                onClick={() => setIsOpen(false)}
              >
                {isOpen && overlay}
              </PseudoBox>

              <Arrow
                className='arrow'
                ref={setArrowElement}
                style={styles.arrow}
                _before={{
                  bg: 'global.elementBg',
                  w: '12px',
                  h: '12px',
                  pos: 'absolute',
                  border: '1px',
                  borderColor: 'borderColour',
                  borderRadius: '2px',
                  zIndex: -1,
                  content: '" "',
                  whiteSpace: 'pre',
                  transform: 'rotate(45deg)',
                  display: !arrow && 'none'
                }}
              />
            </DropdownWrapper>
          </div>,
          container.current
        )}
    </PopperContext.Provider>
  )
}

Dropdown.defaultProps = {
  placement: 'bottom-start',
  arrow: true,
  triggerProps: {
    variant: 'outline'
  },
  size: 'md',
  closeOnSelect: false
}

Dropdown.displayName = 'Dropdown'

Dropdown.propTypes = {
  /**
   * The placement of the dropdown in relation to the trigger element
   */
  placement: PropTypes.oneOf([
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'left',
    'left-start',
    'left-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'auto',
    'auto-start',
    'auto-end'
  ]),
  /**
   * Show or hide the dropdown arrow
   */
  arrow: PropTypes.bool,
  /**
   * The size of the dropdown
   */
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  /**
   * Close the dropdown when a MenuItem is clicked on or selected
   */
  closeOnSelect: PropTypes.bool,

  /**
   * The content of the dropdown (React Node)
   */
  overlay: PropTypes.node.isRequired
}

/// //////////////////////// MENU ITEM ///////////////////////

const MenuItem = (props) => {
  const { children, isDisabled, onClick, ...rest } = props
  const { size, closeOnSelect, setIsOpen } = useContext(PopperContext)

  return (
    <Button
      as='div'
      variant='link'
      title={children}
      borderRadius='none'
      textAlign='left'
      justifyContent='flex-start'
      fontWeight='normal'
      fontSize={size}
      p='auto'
      onClick={() => {
        !isDisabled && onClick && onClick()
        closeOnSelect && !isDisabled && setIsOpen(false)
      }}
      py={size}
      isDisabled={isDisabled}
      {...useMenuItemStyle(props)}
      {...rest}
    >
      {childrenWithProps(children, props)}
    </Button>
  )
}

MenuItem.defaultProps = {
  isDisabled: false
}

MenuItem.propTypes = {
  /**
   * Is the item disabled?
   */
  isDisabled: PropTypes.bool,
  /**
   * Optional onClick handler
   */
  onClick: PropTypes.func
}

export { Dropdown, MenuButton, MenuItem }
