import React, { useState, useRef, createContext, useContext } from 'react'
import { usePopper } from 'react-popper'
import ReactDOM from 'react-dom'
import { useOutsideClick } from '../hooks'
import PseudoBox from '../PseudoBox'
import PropTypes from 'prop-types'
import { Arrow, DropdownWrapper } from './styles'

const PopperContext = createContext()

const PopoverTrigger = (props) => {
  const { setReferenceElement, isOpen, setIsOpen, trigger } = useContext(
    PopperContext
  )

  return (
    <PseudoBox
      ref={setReferenceElement}
      _hover={{
        cursor: 'pointer'
      }}
      display='inline-block'
      onClick={() => (trigger === 'click' ? setIsOpen(!isOpen) : null)}
      onMouseEnter={() => (trigger === 'hover' ? setIsOpen(true) : null)}
      onMouseLeave={() => (trigger === 'hover' ? setIsOpen(false) : null)}
      {...props}
    >
      {props.children}
    </PseudoBox>
  )
}

const PopoverHeader = (props) => {
  const { size } = useContext(PopperContext)
  return (
    <PseudoBox
      w='100%'
      display='flex'
      alignItens='center'
      color='inherit'
      py={3}
      px={3}
      borderBottom='1px'
      borderColor='popover.borderColour'
      fontSize={size}
      fontWeight='semibold'
      {...props}
    >
      {props.children}
    </PseudoBox>
  )
}

const PopoverBody = (props) => {
  return (
    <PseudoBox w='100%' color='inherit' p={3} fontSize='md' {...props}>
      {props.children}
    </PseudoBox>
  )
}

const PopoverContent = (props) => {
  const { children, ...rest } = props
  const {
    setPopperElement,
    setArrowElement,
    dropdownContainer,
    isOpen,
    styles,
    attributes,
    arrow,
    trigger,
    setIsOpen
  } = useContext(PopperContext)

  const baseStyle = {
    bg: props.bg ? props.bg : 'global.elementBg',
    position: 'relative',
    h: 'auto',
    w: 'auto',
    borderRadius: 'popover',
    boxShadow: 'popover',
    border: '1px',
    borderColor: props.bg ? props.bg : 'popover.borderColour',
    minWidth: 250,
    zIndex: 9000
  }

  return isOpen
    ? ReactDOM.createPortal(
        <div
          ref={dropdownContainer}
          onMouseEnter={() => (trigger === 'hover' ? setIsOpen(true) : null)}
          onMouseLeave={() => (trigger === 'hover' ? setIsOpen(false) : null)}
          style={{ zIndex: 9000 }}
        >
          <DropdownWrapper
            {...rest}
            {...baseStyle}
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <PseudoBox
              zIndex={9000}
              bg='global.elementBg'
              borderRadius='popover'
              pos='relative'
              {...rest}
            >
              {isOpen && children}
            </PseudoBox>

            <Arrow
              className='arrow'
              ref={setArrowElement}
              style={styles.arrow}
              _before={{
                bg: props.bg ? props.bg : 'global.elementBg',
                w: '12px',
                h: '12px',
                pos: 'absolute',
                border: '1px',
                borderColor: props.bg ? props.bg : 'popover.borderColour',
                borderRadius: '2px',
                zIndex: -1,
                content: '" "',
                whiteSpace: 'pre',
                transform: 'rotate(45deg)',
                display: !arrow && 'none'
              }}
            />
          </DropdownWrapper>
        </div>,
        document.querySelector('body')
      )
    : null
}

const Popover = (props) => {
  const { placement, arrow, children, size } = props
  const [referenceElement, setReferenceElement] = useState(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const dropdownContainer = useRef(null)
  const setPopperElement = useRef(null)
  useOutsideClick(setPopperElement, setIsOpen)
  const [arrowElement, setArrowElement] = useState(null)
  const { styles, attributes } = usePopper(
    referenceElement,
    setPopperElement.current,
    {
      placement: placement,
      modifiers: [
        { name: 'arrow', options: { element: arrowElement } },
        {
          name: 'offset',
          options: {
            offset: [0, arrow ? 8 : 4]
          }
        }
      ]
    }
  )

  return (
    <PopperContext.Provider
      value={{
        setReferenceElement,
        setPopperElement,
        setArrowElement,
        setIsOpen,
        dropdownContainer,
        isOpen,
        styles,
        attributes,
        size,
        ...props
      }}
    >
      {children}
    </PopperContext.Provider>
  )
}

Popover.propTypes = {
  /**
   * The placement of the dropdown in relation to the trigger element
   */
  placement: PropTypes.oneOf([
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'left',
    'left-start',
    'left-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'auto',
    'auto-start',
    'auto-end'
  ]),
  /**
   * Show or hide the dropdown arrow
   */
  arrow: PropTypes.bool,
  /**
   * The size of the dropdown
   */
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  /**
   * Trigger method
   */
  trigger: PropTypes.oneOf(['click', 'hover']),
  /**
   * Children of the popover.  React node.
   */
  children: PropTypes.node
}

Popover.defaultProps = {
  placement: 'bottom',
  arrow: true,
  size: 'md',
  trigger: 'click'
}

export { Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody }
