import React, { createContext, forwardRef, useContext } from 'react'
import Box from '../Box'
import Flex from '../Flex'
import { useDisclosure } from '../hooks'

import PseudoBox from '../PseudoBox'
import IconButton from '../IconButton'
import Button from '../Button'
import { MdClose } from 'react-icons/md'
import PropTypes from 'prop-types'
import { Scale } from '../Transition'
import ScrollContainer from '../ScrollContainer'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const ModalContext = createContext()

const maxWidths = {
  lg: 1100,
  md: 800,
  sm: 400
}

const baseProps = ({ size, maxWidth }) => {
  return {
    position: 'relative',
    zIndex: 8999,
    borderRadius: 'modal',
    width: '100%',
    maxWidth: maxWidth ? maxWidth : maxWidths[size],
    boxShadow: 'modal',
    overflow: 'hidden'
  }
}

const ModalHeader = ({ children, ...props }) => {
  const { close, showCloseIcon, onClose } = useContext(ModalContext)
  return (
    <DialogTitle>
      <Box
        py={3}
        pl={5}
        pr={3}
        fontSize='20px'
        fontWeight='semibold'
        {...props}
        position='sticky'
        top={0}
        bg='global.elementBg'
      >
        <Flex align='center'>
          {children}
          {showCloseIcon && (
            <IconButton
              size='md'
              variant='ghost'
              ml='auto'
              onClick={() => {
                onClose && onClose()
                close()
              }}
              icon={<MdClose fontSize='20px' />}
            />
          )}
        </Flex>
      </Box>
    </DialogTitle>
  )
}

const ModalFooter = ({ children, showCloseIcon = true }) => {
  const { close, onClose } = useContext(ModalContext)
  return (
    <Box
      py={3}
      pl={5}
      pr={3}
      fontWeight='medium'
      position='sticky'
      bottom='0px'
      bg='global.elementBg'
    >
      <DialogActions>
        <Flex justify='flex-end'>
          {children}
          {showCloseIcon && (
            <Button
              variant='outline'
              onClick={() => {
                onClose && onClose()
                close()
              }}
            >
              Close
            </Button>
          )}
        </Flex>
      </DialogActions>
    </Box>
  )
}

ModalFooter.propTypes = {
  /**
   * Show or hide the close icon
   */
  showCloseIcon: PropTypes.bool
}

const ModalBody = ({ children, ...rest }) => {
  return (
    <Box w='100%' {...rest}>
      {children}
    </Box>
  )
}

const ModalContent = forwardRef(({ children }, ref) => {
  const { close, isOpen, size, onClose, open, scroll } = useContext(
    ModalContext
  )

  return isOpen ? (
    <Dialog
      open={isOpen ? isOpen : open}
      onClose={() => {
        onClose && onClose()
        close()
      }}
      maxWidth={size}
      fullWidth={true}
      scroll={scroll}
    >
      <DialogContent>{children} </DialogContent>
    </Dialog>
  ) : null
})

const ModalButton = forwardRef(({ children, onClick, ...rest }, ref) => {
  const { toggle } = useContext(ModalContext)

  return (
    <PseudoBox
      data-modal={true}
      ref={ref}
      onClick={() => {
        onClick && onClick()
        toggle()
      }}
      {...rest}
    >
      {children}
    </PseudoBox>
  )
})

const Modal = React.forwardRef((props, ref) => {
  const {
    children,
    allowPinchZoom,
    size,
    maxWidth,
    showCloseIcon,
    open,
    onClose,
    scroll
  } = props
  const { isOpen, toggle, close } = useDisclosure(open ? open : false)

  return (
    <ModalContext.Provider
      value={{
        open,
        isOpen,
        toggle,
        close,
        allowPinchZoom,
        size,
        maxWidth,
        showCloseIcon,
        onClose,
        scroll
      }}
    >
      {children}
    </ModalContext.Provider>
  )
})

Modal.propTypes = {
  /**
   * The size of the modal.
   */
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  /**
   * The maximum width of the modal (overwrites the size)
   */
  maxWidth: PropTypes.number,
  /**
   * Allow user to pinch screen to zoom
   */
  allowPinchZoom: PropTypes.bool,
  /**
   * Show the close icon in the modal header (if present)
   */
  showCloseIcon: PropTypes.bool,
  /**
   * An optional function to fire when the modal closes
   */
  onClose: PropTypes.func,
  /**
   * The scroll type.  Body or Modal
   */
  scroll: PropTypes.oneOf(['paper', 'body'])
}

Modal.defaultProps = {
  allowPinchZoom: true,
  showCloseIcon: true,
  scroll: 'body',
  size: 'md'
}

export {
  Modal,
  ModalHeader,
  ModalButton,
  ModalContent,
  ModalFooter,
  ModalContext,
  ModalBody
}
