/** @jsx jsx */
import { jsx, ThemeContext } from '@emotion/react'
import { createContext, useContext, useEffect, useState } from 'react'
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import { ToastWrapper } from '../Toast'
import {
  createTheme,
  ThemeProvider as MuiProvider
} from '@material-ui/core/styles'
import useDarkMode from 'use-dark-mode'
import merge from 'lodash.merge'
import get from 'lodash.get'
import baseTheme from '../theme'
import GlobalTheme from '../CSSReset'

// This context handles the color mode (light or dark) of the UI
export const ColorModeContext = createContext({
  colorMode: 'light',
  toggleColorMode: () => {}
})

// merge the color mode with the base theme
// to create a new theme object
const getTheme = ({ colorMode, override }) => {
  const merged = merge(baseTheme, override)
  return merge({}, merged, {
    colors: get(merged.colors.modes, colorMode, merged.colors)
  })
}

const ThemeProvider = ({ value, children, override, force }) => {
  const [manualMode, setManualMode] = useState(value)

  const manualToggle = () => {
    if (manualMode === 'light') {
      setManualMode('dark')
    }

    if (manualMode === 'dark') {
      setManualMode('light')
    }
  }

  const darkMode = useDarkMode(false)

  const { value: isDarkMode, toggle } = useDarkMode(false)
  const colorMode = force != null ? force : isDarkMode ? 'dark' : 'light'
  const theme = getTheme({ colorMode, override })

  useEffect(() => {
    if (force === 'light') {
      darkMode.disable()
    }
  }, [darkMode, force])

  const childContext =
    value != null
      ? { colorMode: manualMode, toggleColorMode: manualToggle }
      : { colorMode, toggleColorMode: toggle }

  const MuiTheme = createTheme({
    palette: {
      primary: { main: baseTheme.colors.primary, contrastText: '#fff' },
      background: {
        default: '#18181B',
        paper: colorMode === 'dark' ? '#262628' : '#ffffff'
      },
      type: colorMode
    },
    overrides: {
      MuiButton: {
        textPrimary: {
          color: colorMode === 'dark' ? '#ffffff' : '#262628'
        }
      },
      MuiDialog: {
        root: {
          zIndex: '9999 !important',
          padding: 0
        }
      },
      MuiDialogContent: {
        root: {
          padding: '0px !important'
        }
      },
      MuiDialogTitle: {
        root: {
          padding: 0
        }
      }
    },
    typography: {
      fontFamily: baseTheme.fonts.body
    }
  })

  return (
    <ColorModeContext.Provider value={childContext}>
      <MuiProvider theme={MuiTheme}>
        <EmotionThemeProvider theme={theme}>
          <GlobalTheme />
          <ToastWrapper>{children}</ToastWrapper>
        </EmotionThemeProvider>
      </MuiProvider>
    </ColorModeContext.Provider>
  )
}

const DarkMode = (props) => <ThemeProvider value='dark' {...props} />
const LightMode = (props) => <ThemeProvider value='light' {...props} />

const useColorMode = () => {
  const context = useContext(ColorModeContext)
  if (context === undefined) {
    throw new Error('useColorMode must be used within a ColorModeProvider')
  }
  return context
}

export const useTheme = () => {
  const theme = useContext(ThemeContext)
  if (theme === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }
  return theme
}

export { useColorMode, DarkMode, LightMode, ThemeProvider }
